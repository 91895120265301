// ./components/Header.jsx

// React
import React from 'react';

// Chakra
import {
  Box, Center, HStack, IconButton, useColorModeValue,
} from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';
import { AiFillHome } from 'react-icons/ai';

const Header = React.forwardRef(({ goHome, onSettingsOpen }, ref) => {
  const headerColor = useColorModeValue("rgba(23, 25, 35, 0.10)", "rgba(23, 25, 35, 0.90)");

  return (
    <HStack className="oai-header" ref={ref} width="100%" bg={headerColor} p="8px" pl="16px" pr="16px">
      <IconButton 
        aria-label="Home" 
        icon={<AiFillHome />} 
        onClick={goHome}
      />
      <Center width="100%">
        <Box fontSize="32px">orison.ai</Box>
      </Center>
      <IconButton 
        aria-label="Open settings" 
        icon={<SettingsIcon />}
        onClick={onSettingsOpen}
      />
    </HStack>
  );
});

export default Header;
